<template>
  <v-chip
    v-bind="$attrs"
    class="pl-0"
    :color="!variant || variant === 'tonal' ? `domain_${type.toLowerCase()}` : undefined"
    :variant="variant ?? 'tonal'"
    :density="density ?? 'comfortable'"
  >
    <template #prepend>
      <v-avatar :size="avatarSize" class="mr-2 text-body-2" :color="`domain_${type.toLowerCase()}`">
        <v-icon size="small" :icon="`$domain_${type.toLowerCase()}`" />
      </v-avatar>
    </template>

    <span class="text-high-emphasis">
      <slot>{{ !modelTitle ? $t(`admin.domains.type.${type}`) : modelTitle }}</slot>
    </span>
  </v-chip>
</template>

<script setup lang="ts">
import type { DomainType } from "~~/stores/organization.store"

const props = defineProps<{
  type: DomainType
  modelTitle?: string
  density?: "comfortable" | "compact" | "default"
  variant?: "tonal" | "text"
}>()
const { type, modelTitle, density, variant } = toRefs(props)

const avatarSize = computed(() => {
  if (density?.value === "compact") return "1.3rem"
  if (density?.value === "comfortable" || !density.value) return "1.5rem"
  return "2rem"
})
</script>
